a.link_gravatar{
	display:block;
	width:100%;
	text-align: center;

}
.AvatarImage {
	display:block;
  border-radius: 50%;
  max-width: 100%;
  max-height: 100%;
  border: 2px solid #FAF0E6;
  box-sizing: border-box;
  background-color:#FAF0E6;
}
.AvatarMaskedImage{
  width:100%;
  height:auto;
  max-width: 256px;
  background-size: contain;
  background-repeat:no-repeat;
  /*mask-mode: alpha;
  mask-image: url(../../assets/avatar/avatar-mask-1024-black.png);
  mask-size: 100%;
  mask-repeat: no-repeat;
  mask-position: center;*/
}