@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,300;0,600;0,900;1,300;1,600;1,900&display=swap");

html {
  /* https://allthingssmitty.com/2020/05/11/css-fix-for-100vh-in-mobile-webkit/ */
  height: -webkit-fill-available;
  scroll-boundary-behavior: none;
}
body {
  min-height: 100vh;
  min-height: -webkit-fill-available;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
body {
  margin: 0;
  font-family: "Manrope", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(45, 45, 45);
  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none; /* Firefox all */
  -ms-user-select: none; /* IE 10+ */
  user-select: none;
}
@media screen and (min-width: 480px) {
  body {
    background-color: black;
  }
}
#root {
  position: relative;
  flex-grow: 2;
  display: block;
  max-width: 480px;
  max-height: 960px;
  overflow: hidden;
  height: 100%;
  width: 100%;
  margin: auto;
  background-color: rgb(45, 45, 45);
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button {
  display: inline-block;
  padding: 10px 10px;
  margin-bottom: 20px;
  box-sizing: border-box;
  font-size: 1rem;
  width: 49%;
  margin-right: 2%;
  border: thin #999 solid;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  font-family: inherit;
  text-transform: uppercase;
}
a {
  display: block;
  text-decoration: none;
  color: #FAF0E6;
  margin-top: 20px;
}

.moderation div#root{
  max-width:100%;
  max-height:100%;
}

.moderation div#root div div:nth-child(4){
  max-width:100%;
  max-height:100%;
}
.moderation > div#root > div > div:nth-child(1) > svg{
  display:none;
}
.moderation > div#root > div > div:first-child{
  background:no-repeat;
  background-position: 6000px 6000px;
}