.AddStatementPage {
  position: relative;
  display: grid;
  height: 100%;
  grid-template-rows: max-content max-content auto;
}
.AddStatementPage section {
  /* moved
   position: relative;
	display: block;
		margin-left: -20px;
	margin-right: -20px;
	padding:15px 25px;
	background-color: rgba(255,255,255,0.5);
	-webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px; */
}
.AddStatementPage textarea {
  position: relative;
  display: block;
  width: 100%;
  height: 120px;
  margin: auto;
  padding: 10px 10px;
  margin-bottom: 20px;
  box-sizing: border-box;
  font-size: 1rem;
  font-family: inherit;
  border: none;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

.AddStatementPage label {
  position: relative;
  display: block;
  font-size: 0.8rem;
  margin-bottom: 10px;
  color: #555;
}

.AddStatementPage label input {
  position: relative;
  display: block;
  width: 100%;
  margin-right: 25px;
  box-sizing: border-box;
  padding: 10px 10px;
  font-size: 1rem;
  margin-top: 5px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  border: none;
}
.AddStatementPage button {
  display: inline-block;
  padding: 10px 25px;
  margin-bottom: 20px;
  margin-top: 10px;
  box-sizing: border-box;
  font-size: 0.9rem;
  width: fit-content;
  margin-right: 2%;
  border: none;
  border-radius: 2rem;
  font-family: inherit;
  background-image: linear-gradient(to right, rgb(123, 235, 180), rgb(90, 105, 235));
  text-transform: uppercase;
  color: #FAF0E6;
  font-weight: 600;
}


.AddStatementPage > section > p:nth-child(3){
 font-weight:600;
}