.MyProfilePage {
}

.MyProfilePage section {
  /* JM moved to ../App/ContentSection.module.css */
}
.gravatar_caption {
  position: relative;
  display: none;
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
}

.gravatar_caption a {
  color: #333;
}

.profile_edit_field {
  position: relative;
  display: block;
}

.profile_edit_field label {
  position: relative;
  display: block;
  margin-bottom: 10px;
  font-size: 0.8rem;
}

.profile_edit_field input {
  display: block;
  width: 100%;
  margin: auto;
  padding: 15px 10px;
  margin-bottom: 20px;
  box-sizing: border-box;
  font-size: 1rem;
  font-family: inherit;
  border: none;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

.MyProfilePage button {
  margin-top: 10px;
  text-transform: capitalize;
  font-size: 0.9rem;
  font-weight: 600;
  border: none;
  /* -webkit-border-radius: 1em;
  -moz-border-radius: 1em; */
  border-radius: 2em;
  font-family: inherit;
  background-image: linear-gradient(to right, rgb(123, 235, 180), rgb(90, 105, 235));
  text-transform: uppercase;
  color: #FAF0E6;
  width: 33%;
}


.AvatarPickerButton{
  display: inline-block;
  padding: 10px 10px;
  margin-bottom: 0px;
  box-sizing: border-box;
  font-size: 0.9rem;
  /* width: 33%; */
  margin-right: 2%;
  border: none;
  border-radius: 2rem;
  font-family: inherit;
  background-image: linear-gradient(to right, rgb(123, 235, 180), rgb(90, 105, 235));
  color: #FAF0E6;
  text-transform: uppercase;
  font-weight: 600;
  height: -moz-fit-content;
  height: fit-content;
  align-self: center;
  margin:auto;
  margin-bottom:18px;
}

.ButtonBar{

  display:grid;
  grid-template-columns: 48% 4% 48%;
}
.ButtonBar > *{
  width:100% !important;
  text-align:center;
}