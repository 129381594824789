.AboutPage {
  position: relative;
  /* display: flex; */
  /* _width: 100%; */
  height: auto;
  padding: 20px;
  margin: 0;
  margin-top:120px;
  background-color: rgb(45,45,45);
  flex-wrap: wrap;
  flex-flow: column;
  box-sizing: border-box;
  align-content: center;
  align-items: center;

  _box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.5);
}
.AboutPage h3{
  color:#FAF0E6;
}

.AboutPageHeader {
  color: #FAF0E6;
  margin-bottom: 20px !important;
  align-self: center;
  margin-top: auto;
}
.AboutPageSubHeader {
  color: #333#FAF0E6;
}
p {
  margin-bottom: 20px;
  align-self: center;
  color: #FAF0E6;
}
p:last-child {
  margin-bottom: auto;
}
