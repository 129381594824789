.Buttons {
  height: 30px;
  display: flex;
  z-index: 30;
  margin-left: calc(100% - 30px);
    margin-top: 8px;
}
.CommentsButton,
.ShareButton {
  margin:0;
  padding:0;
  height:32px;
  width:32px;
  margin-right: 1rem;
  background:none;
}
.CommentsButton svg,
.ShareButton svg {
  height: 24px;
  fill: #FAF0E6;
}
.CommentsButton span {
  font-size: 0.7rem;
  margin-top:-7px;
}
.CommentsButton svg{
  margin-top:-4px;
}
.ShareButton{
  margin-left:6px;
}
.ShareButton svg{
  margin-left:4px;
  width:22px;
}
.RemoveButton {
  height: 24px;
  width:24px;
  margin-left:0.5rem;
}
.RemoveButton path {
  /* fill:rgba(136,143,46,0.5); */
  fill: #FAF0E6;
}
