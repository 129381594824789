.Menu {
  transform: translateX(-100%);
  transition: transform 0.2s ease-in-out;
  background-color: #9baa0f;
}
.Menu.Show {
  transform: translateX(0);
}
.Backdrop{
  opacity: 1;
}
.List {
  position: relative;
  height:100%;
  /* display: grid;
  grid-template-columns: 20px auto 20px; */
  list-style: none;
  /* height: auto; */
  margin: 0;
  padding: 0;
  padding-top: 10%;
  padding-bottom: 15%;
  box-sizing: border-box;
  display: grid;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns: 50px 1fr 250px 1fr 50px;
  overflow:hidden;
}

.List > div{
  display:inline;
}
.List > div:nth-child(1) {
  display:inline;
  grid-area: 2 / 2 / 2 / 4;
}

.List > div:nth-child(2) {
  grid-area: 3 / 3 / 3 / 4;
}

.List > div:nth-child(3) {
  grid-area: 4 / 2 / 4 / 5;
}

.List > div:nth-child(4) {
  grid-area: 5 / 3 / 5 / 6;
}

.List > div:nth-child(5) {
  grid-area: 6 / 2 / 6 / 4;
}

.List > div:nth-child(6) {
  grid-area: 7 / 3 / 7 / 4;
}
.List > div:nth-child(7) {
  grid-area: 8 / 2 / 8 / 4;
}

.List div a {
  /* display: block; */
  text-decoration: none;
  align-self: center;
  color: #eee;
  font-size: 2.4rem;
  font-weight: 600;
  text-transform: lowercase;
  font-variant: all-small-caps;
  margin-right:0px;
}

.ButtonMask{
  background-size:90%;
  /*mask-mode:alpha;
  mask-image: url(../../assets/backgrounds/caim-menu-background.png);
  mask-size: 150%;
  mask-repeat: no-repeat;
  mask-position: 50% 30%;*/
  /* background: rgba(255, 255, 255, 0.6); */
  padding:10%;
}