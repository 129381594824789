.Buttons {
  position: absolute;
  top: 12px;
  left: 60px;
  height: 30px;
  display: flex;
  z-index: 30;
}
.CommentsButton,
.ShareButton {
  margin-right: 1rem;
}
.CommentsButton span {
  font-size: 0.7rem;
}

.CommentsButton svg,
.ShareButton svg {
  height: 28px;
   fill: #FAF0E6;
   
}
.RemoveButton {
  height: 24px;
  width:24px;
  margin-left:0.5rem;
}
.RemoveButton path {
  /* fill:rgba(136,143,46,0.5); */
}


.UserRow{
  width:100%;
  display: grid;
  grid-template-columns:  50px  calc(100% - 150px) 50px 50px;
  grid-row: 1/2;
  align-self: center;
  margin-top: 0;
  margin-bottom: 10px;
  position: relative;
  width: 100%;
  /* padding: 0 25px;
  padding-right: 5px;
  padding-top: 10px; */
  box-sizing: border-box;
  margin-bottom: 5px;
  grid-column-gap: 20px;
  padding-left:20px;
  margin-left:-20px;
  margin-right:-20px;
  padding-bottom:12px;
  padding-right:32px;
  margin-bottom:6px;
}

.UserText {
  /* margin-left: 10px; */
}


.UserText > span{
  display:inline-block;
  font-size: 1.1rem;
  font-weight: 800;
  color: #FAF0E6;
  text-decoration: none;
}
.UserRow button {
  position: relative;
  display: none;
  width: 100%;
  grid-column: 2/3;
  grid-row: span 1;
  margin: 0;
  align-self: center;
}
.UserRow span.ManifestoDrag {
  position: relative;
  display: block;
  width: 32px;
  height: 40px;
  overflow: hidden;
  grid-column: 2/3;
  grid-row: 1/2;
  margin: 0;
  opacity: 0.66;
  align-self: flex-start;
}
.UserRow span svg {
  fill: #FAF0E6;
  height: 100%;
}

.UserText > span.UserDeleted {
  text-decoration: line-through;
  opacity:.6;
}

.Avatar{
  margin-top:0px;
  width:54px !important;
  height:auto;
}

/* .UserRow{
  display:flex;
} */
span svg{
width: 100%;
height: Auto;
}