.SharePanel {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: center;
  align-content: center;
  width: 100vw;
  max-width: 480px;
  padding: 25px;
  box-sizing: border-box;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  background-color: rgba(90, 105, 235,0.8);
  z-index: 10000;
}
.CloseButton {
  grid-column: 1/4;
  grid-row: 1/2;
  text-align: right;
  color:#333;
}
.SharePanel a {
  display: inline-block;
  grid-column: span 1;
  grid-row: 2/3;
  text-align: center;
}

.SharePanel a:first-of-type {
  grid-column: 1/4;
  grid-row: 3/4;
}
.SharePanel a img {
  max-height: 5rem;
  height: 40px;
  width: 40px;
}
.Feedback {
  grid-column: 1/4;
  grid-row: 1/2;
  text-align: left;
  color:#333;
}
.CloseButton svg {
  width: 30px;
}
.CloseButton svg path {
  fill: #454a10;
}

.ShareButton{
  display: inline-block;
  padding: 5px 10px;
  margin-bottom: 0px;
  box-sizing: border-box;
  font-size: 1.4rem;
  width: 33%;
  margin-right: auto;
  margin-left: auto;
  border: none;
  border-radius: 2rem;
  font-family: inherit;
  background-image: linear-gradient(to right, rgb(123, 235, 180), rgb(90, 105, 235));
  text-transform: uppercase;
  color: #FAF0E6;
  font-weight: 600;
  height: -moz-fit-content;
  height: fit-content;
  align-self: center;
}