.GradientBackground {
  pointer-events: none;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.GradientBackground svg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.GradientBackground rect {
  transition: opacity 0.3s ease-in-out;
}
