.RequestResetPage {
  position: relative;
  display: grid;
  height: 100%;
  grid-template-rows: 1fr 2fr;
}
.RequestResetPage .PageHeader {
  /* has no effect */
  grid-row: 1/2;
  grid-column: 1/2;
}
.RequestResetPage p {
  position: relative;
  grid-row: 1/2;
  grid-column: 1/2;
  margin-top: 140px;
}
.RequestResetPage form {
  position: relative;
  display: block;
  align-self: flex-start;
}

.RequestResetPage form input {
  display: block;
  width: 100%;
  margin: auto;
  padding: 15px 10px;
  margin-bottom: 20px;
  box-sizing: border-box;
  font-size: 1rem;
  font-family: inherit;
  border: thin #999 solid;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  background-color: #FAF0E6;
}

.RequestResetPage form button {
  display: inline-block;
  padding: 10px 10px;
  margin-bottom: 20px;
  box-sizing: border-box;
  font-size: 1rem;
  width: auto;
  border:0;
  border-radius: 2rem;
  font-family: inherit;
  color:#FAF0E6;
  background-image: linear-gradient(to right, rgb(123, 235, 180), rgb(90, 105, 235));
  text-transform: uppercase;
}
