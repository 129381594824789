.PageHeader {
  position: relative;
  display: block;
  margin: auto;
  margin-bottom:20px;
  margin-top:25px;
  width: 100%;
  color: #FAF0E6;
  text-transform: uppercase;
  font-size:2.6rem;
}
