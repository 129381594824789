.ProfilePage section {
  /* moved 
	background-color: rgba(255,255,255,0.5);
	padding: 25px;
	margin-left: -25px;
	margin-right: -25px;
	margin-top: 20px;
	*/
}

.EditButton{
  position: relative;
  display: block;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
  margin-left: 0;
  padding: 10px 20px;
  color: #FAF0E6;
  border-radius: 2rem;
  ackground-image: linear-gradient(to right, rgb(123, 235, 180), rgb(90, 105, 235));
}
