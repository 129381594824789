.Header {
  display: grid;
  grid-template-columns: 20px 48px auto 20px;
  padding-top: calc(5px + var(--header-x-pad, 0));
  box-sizing: border-box;
  z-index:10001;
}
.LogoLink {
  position: absolute;
  display: block;
  width: 64px;
  height: 64px;
  top: 10px;
  right: 10px;
  justify-self: flex-end;
  grid-row: 1/2;
  grid-column: 3/5;
  margin: 0;
  margin-top: 0px;
  text-align: right;
  justify-self: right;
  color: #FAF0E6;
}
.MenuToggle {
  grid-row: 1/2;
  grid-column: 2/3;
}
.Logo {
  position: relative;
  display: block;
  width: 45px;
  margin-top:.15rem;
}
.AppIco {
  position: relative;
  height:64px;
  display: block;
  width: 100%;
  background-size:contain;
}
.AvatarIco {
  position: relative;
  height:64px;
  display: block;
  width: 100%;
  background-repeat:no-repeat;
  /* background-color:#c8d72c;
  background-size:contain; */
  /*mask-mode:alpha;
  mask-image: url(../../assets/avatar/avatar-mask-1024-black.png);
  mask-size: 64px;
  mask-repeat: no-repeat;
  mask-position: center;*/
}
.LogoLink {
}
.LogoLink a {
  margin-top: 0;
}



.LogoLinkSignup {
  position: absolute;
  display: block;
  max-width: 152px;
  height: 40px;
  top: 24px;
  right: 10px;
  justify-self: flex-end;
  grid-row: 1/2;
  grid-column: 3/5;
  margin: 0;
  margin-top: 0px;
  text-align: right;
  justify-self: right;
  color: #FAF0E6;
  background-image: linear-gradient(to right, rgb(123, 235, 180), rgb(90, 105, 235));
  text-transform: uppercase;
  border-radius:6rem;
}
.LogoLinkSignup a {
  margin-top: 0;
}
.LogoLinkSignup a span{
  float:left;
  /* width:3.14rem; */
  padding:0.06rem .16rem 0rem .8rem;
  font-size:1.4rem;
  font-weight:600;
}
