.ShareButton svg {
  max-height: 100%;
  max-width: 100%;
  fill: #FAF0E6;
}

.ShareButton{

  background-image: linear-gradient(to right, rgb(123, 235, 180), rgb(90, 105, 235));
  border-radius: 40px;
  padding: 9px 10px 0px 10px;
}