
.Button{
  display: inline-block;
  padding: 10px 10px;
  margin-bottom: 0px;
  box-sizing: border-box;
  font-size: 0.9rem;
  /* width: 33%; */
  margin-right: 2%;
  border: none;
  border-radius: 2rem;
  font-family: inherit;
  background-image: linear-gradient(to right, rgb(123, 235, 180), rgb(90, 105, 235));
  color: #FAF0E6;
  text-transform: uppercase;
  font-weight: 600;
  height: -moz-fit-content;
  height: fit-content;
  align-self: center;
  margin:auto;
  margin-bottom:18px;
}